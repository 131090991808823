.MainSection{
    width: 80%;
    margin: auto;
}

.TitleDiv p{
    margin-top: 80px;
}

.TitleDiv p{
    text-align: center;
    font-size: 1.3em;
    font-weight: 600;
    color: rgb(32, 32, 30);
}

.soloAWADS {
    width: 80%;
    margin: auto;
    margin-top: 30px;
}

.EachAWADSDiv {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.PhotoDiv {
    flex: 0.8;
}

.PhotoDiv img{
    border-radius: 12px;
    filter: drop-shadow(0 0 0.35rem rgb(24, 22, 22));
}

.TextDiv {
    flex: 1;
    padding: 10px 20px;
}

.AWADSTitle {
    font-size: 1.5em;
    font-weight: 400;
    color: #d81516;
    text-decoration: underline;
}

.AWADSDesc {
    margin-top: 5px;
    font-size: 1.2em;
    color: #353232;
}

.EachAWADSTeamDiv {
    width: 88%;
    margin: auto;
    margin-bottom: 30px;
}

.TeamPhotoDiv img{
    border-radius: 12px;
}

@media screen and (max-width: 1300px) {
    .MainSection {
        width: 95%;
        margin: auto;
    }

    .MainImage {
        width: 80%;
        margin: auto;
        margin-top: 20px;
    }

    .ThreeInOne {
        display: flex;
        flex-wrap: wrap;
        width: 90%;
        margin: auto;
        overflow: hidden;
    }

    .EachAWADSDiv {
        width: 95%;
        margin: auto;
        margin-bottom: 30px;
        display: block;
    }

    .AwadrsName{
        font-size: 22px;
    }

    .AwadrsList {
        font-size: 18px;  
    }

    
}