F.MainSection {
    width: 80%;
    margin: auto;
}

.CourseTitleDiv {
    width: 18em;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 10px 30px;
    border: 2px #d81013 solid;
    border-radius: 12px;
}

.CourseTitleDiv p {
    text-align: center;
    color: #d81013;
    font-size: 1.6em;
    font-weight: 500;
}

.LinkDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.LinkDiv p,
a {
    font-size: 1.5em;
    margin-left: 20px;
    margin-right: 20px;
}

.LinkDiv p {
    color: #2b205b;
}

.LinkDiv a {
    color: #d81013;
    text-decoration: underline;
}

.CourseDiv {
    width: 40%;
    margin: auto;
    margin-bottom: 80px;
}

.CourseDiv2 {
    width: 75%;
    margin: auto;
    margin-bottom: 80px;
}

.DescDiv {
    width: 50%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 50px;
    padding: 10px 20px;
    border: 3px #2b205b solid;
    border-radius: 5px;
}

.DescDiv2 {
    width: 40%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 50px;
    padding: 10px 20px;
    border: 3px #2b205b solid;
    border-radius: 5px;
}

.DescDiv p {
    font-size: 1.3em;
    font-weight: 600;
    color: #222124;
}

.ContactDiv {
    width: 30%;
    margin: auto;
    padding: 10px 20px;
    border: 3px #2b205b solid;
    border-radius: 5px;
    text-align: center;
    font-size: 1.2em;
}

.ThreeInOne {
    display: flex;
    flex-wrap: nowrap;
}

.EachCourseImg {
    flex: 1;
    margin: 5px 15px 5px 15px;
    box-shadow: 5px 5px 12px rgb(0, 0, 0);
}


@media screen and (max-width: 1300px) {
    .MainSection {
        width: 95%;
        margin: auto;
    }

    .CourseDiv {
        width: 90%;
        margin: auto;
        margin-bottom: 80px;
    }

    .CourseTitleDiv {
        width: 85%;
        margin: auto;
        margin-top: 20px;
        padding: 10px 30px;
        border: 2px black solid;
        border-radius: 12px;
    }

    .CourseTitleDiv p {
        text-align: center;
        color: #d81013;
        font-size: 20px;
        font-weight: 500;
    }

    .LinkDiv p,
    a {
        font-size: 22px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .ThreeInOne {
        display: block;
        flex-wrap: nowrap;
    }

    .EachCourseImg img{
        width: 100%;
    }

    .DescDiv {
        width: 80%;
        margin: auto;
    }

    .DescDiv2 {
        width: 70%;
        margin: auto;
        margin-top: 30px;
    }

    .ContactDiv {
        width: 60%;
        margin: auto;
        margin-top: 30px;
        padding: 10px 20px;
        border: 3px #2b205b solid;
        border-radius: 5px;
        text-align: center;
        font-size: 1.2em;
    }

}