@import url('https://fonts.cdnfonts.com/css/porter-sans-block');

.MainSection {
   width: 80%;
   margin: auto;
}

.TitleDiv {
   margin-top: 30px;
   margin-bottom: 30px;
   font-size: 3em;
   text-align: center;
}

.TitleDiv p {
   font-family: 'Porter Sans Block', sans-serif;
}

.PartnersDiv {
   margin: auto;
   display: flex;
   flex-wrap: wrap;
   flex-direction: row;
   width: 80%;
   /* Ensure it takes the full width */
   justify-items: center;
   justify-content: center;
   align-items: center;
}

.PartnersImg {
   display: block;
   flex: 1;
   overflow: hidden;
   margin-left: 20px;
   margin-right: 20px;
}

img {
   width: 100%;
   /* Make images fill their containers */
   height: 100%;
   /* Maintain aspect ratio */
}

.Copyright a{
   margin-top: 30px;
   margin-bottom: 30px;
   text-decoration: none;
   font-size: 1em;
   text-align: center;
   color: #d81213;
}

.ChannelVideo {
   width: 80%;
}

@media screen and (max-width: 1300px) {
   .TitleDiv {
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 30px;
      text-align: center;
   }

   .PartnersImg {
      display:contents;
      overflow: hidden;
      margin-left: 20px;
      margin-right: 20px;
   }

   .PartnersImg img{
      margin-top: 10px;
   }

   .Copyright {
      font-size: 12px;
   }
}