.MainSection {
    width: 80%;
    margin: auto;
}

.BannerImg {
    width: 90%;
    margin: auto;
    margin-top: 80px;
}

.BannerImg img{
    filter: drop-shadow(0 0 0.75rem rgb(90, 89, 89));
    border-radius: 8px;
}

.BannerImg img,
.AboutImgDiv img,
.AboutImgDiv2 img {
    width: 100%;
    height: auto;
    object-fit: cover;
}


.AboutDiv{
    padding: 8px 20px ;
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.AboutTextDiv {
    padding: 10px 20px;
    text-align: center;
    flex: 1;
}

.Title {
    text-align: center;
    font-size: 2.3em;
    font-weight: 600;
    color: #242e70;
}

.AboutContent{
    text-align: center;
    font-size: 1.1em;
    color: #081d79;
    margin-top: 10px;

}

.AboutImgDiv {
    flex: 1.2;
}

.AboutImgDiv img{
    width: 100%;
    border-radius: 12px;
    filter: drop-shadow(0 0 0.35rem rgb(48, 46, 46));
}

.fade_in_img {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.fade_in_img.visible {
    opacity: 1;
}

.SubTitle {
    text-align: center;
    font-size: 1.3em;
    color: #242e70;
}

.AboutContent2 {
    padding: 1em;
    font-size: 1.2em;
    color: #081d79;
    margin-top: 10px;
}

.AboutImgDiv2 {
    text-align: center;
    flex: 1;
}

.AboutImgDiv2 img{
    width: 80%;
    height: 80%;
}

.ServeTitle{
    color: #d91213;
    font-weight: 500;
    font-size: 1.6em;
    text-align: center;
}

ul.Listpoint {
    font-size: 1.4em;
    text-align: left;
    display: inline-block;
}

.Listpoint li {
    margin-top: 10px;
}

@media screen and (max-width: 1300px) {
    .MainSection {
        width: 95%;
        margin: auto;
    }

    .AboutDiv{
        display: block;
        width: 95%;
        margin: auto;
        margin-top: 40px;
        margin-bottom: 40px;
        overflow: hidden;
    }
 }