.TitleDiv {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.MainSection hr {
    border: 2px solid #d81213;
}

.ThreeInOne {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    margin: auto;
}

.EachProduct {
    width: 30%;
    margin: auto;

}

.EachProduct2 {
    width: 30%;
    margin: auto;
    margin-bottom: 50px;
}

.btn{
    color: black;
    background: black;
}

.MainPhoto {
    margin: auto;
    width: 70%;

}

.MainPhoto2 {
    margin: auto;
    width: 70%;
    margin-bottom: 120px;
}

.ImageSliderImg {
    width: 10%;
    height: 10%;
}

.OneImageSliderImg {
    margin: auto;
    width: 250px;
    height: 250px;
}

.ProductName {
    margin-top: 10px;
    padding: 20px 30px;
    font-size: 1.5em;
    font-weight: 500;
    text-align: center;
}

.ProductName2 {
    color: #1643f8;
    font-size: 0.9em;
    text-decoration: underline;
}

.ProductPrice {
    margin-top: 10px;
    padding: 20px 30px;
    font-size: 0.9em;
    text-align: center;
    color: #6e9cb8;
}


@media screen and (max-width: 1300px) {
    .ThreeInOne {
        display: block;
        width: 90%;
        margin: auto;
    }

    .EachProduct {
        width: 90%;
        margin: auto;
        margin-bottom: 20px;
    }
}