.MainSection {
    width: 80%;
    margin: auto;
}

.TitleDiv {
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 2.1em;
    font-weight: 500;
    text-align: center;
    text-decoration: underline;
    color: #db2023;
}

.MainImageDiv {
    width: 45%;
    margin: auto;
}

.MainImageDiv img{
    border-radius: 2%;
    filter: drop-shadow(0 0 0.75rem rgb(48, 40, 42));
}

.DescPhoto {
    margin-top: 30px;
    margin-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
}

.DescriptonDiv {
    flex: 0.8;
    padding: 20px 30px;
}

.DescTitle {
    font-weight: 600;
    font-size: 1.6em;
    text-align: center;
    margin-bottom: 20px;
}

.DescriptonMessage {
    padding: 30px 20px;
    font-size: 1.2em;
}

.PhotoDiv {
    flex: 1.2;
}

.PhotoDiv img {
    width: 80%;
    border-radius: 12px;
    filter: drop-shadow(0 0 0.75rem rgb(48, 40, 42));
}

.DescPhoto2 {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
}

.DescriptonDiv2 {
    flex: 0.8;
    padding: 10px 10px;
}

.DescTitle2 {
    font-weight: 600;
    font-size: 1.6em;
    text-align: center;
    margin-bottom: 20px;
}

.DescriptonMessage2 {
    padding: 10px 20px;
    font-size: 1.2em;
}

.PhotoDiv2 {
    flex: 1.2;
}

.PhotoDiv2 img {
    width: 80%;
    border-radius: 12px;
    filter: drop-shadow(0 0 0.75rem rgb(48, 40, 42));
}

@media screen and (max-width: 1300px) {
    .MainSection {
        width: 95%;
        margin: auto;
    }

    .MainImageDiv {
        width: 90%;
        margin: auto;
    }

    .DescPhoto {
        margin-top: 30px;
        margin-bottom: 60px;
        display: block;
    }

    .PhotoDiv {
        flex: 1.2;
        text-align: center;
    }
    
    .PhotoDiv img{
        width: 90%;
    }

    .DescPhoto2 {
        margin-top: 30px;
        display: block;
        text-align: center;
    }

    .DescPhoto2 img{
        width: 90%;
    }
    
}