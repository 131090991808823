@import url('https://fonts.cdnfonts.com/css/porter-sans-block');

.MainSection {
    width: 100%;
}

.ChannelDiv{
    border: 2px #d81013 solid;
    padding: 8px 5px;
    border-radius: 12px;
    width: 25%;
    margin: auto;
}

.ChannelTitle {
    font-family: 'Porter Sans Block', sans-serif;
    font-size: 2.2em;
    text-align: center;
    color: #d81013;
}

.ChannelImgDiv {
    margin-top: 20px;
    text-align: center;
}

.Img{
    width: 100%;
    height: 300px;
    filter: grayscale(30%);
}

.BannerDiv {
    margin-top: 30px;
    width: 100%; /* Ensure it takes the full width */
    background-color: black;
    text-align: center;
}

.BannerImg {
    display: block;
    flex: 2; 
    overflow: hidden;
}

.BannerDiv img {
    width: 60%; /* Make images fill their containers */
    height: 350px;
    
}

@media screen and (max-width: 1300px) {

    .ChannelVideo {
        width: 98%;
    }

    .ChannelDiv{
        border: 2px #d81013 solid;
        padding: 8px 5px;
        border-radius: 12px;
        width: 80%;
        margin: auto;
    }

    .ChannelTitle {
        font-family: 'Porter Sans Block', sans-serif;
        font-size: 26px;
        text-align: center;
        color: #d81013;
    }

    .BannerDiv {
        margin-top: 30px;
        width: 100%; /* Ensure it takes the full width */
        background-color: black;
        text-align: center;
    }

    .BannerDiv img {
        width: 100%; /* Make images fill their containers */
        height: auto;
        
    }
}