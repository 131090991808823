.MainSection {
    width: 80%;
    margin: auto;
}

.PageTitleDiv { 
    margin: auto;
    margin-top: 30px;
    text-align: center;
    font-size: 28px;
    width: 30%;
}

.PageTitleDiv p {
    border: 3px #d81013 solid;
    padding: 10px 20px;
    color: #d81013;
    border-radius: 8px;
    text-decoration: underline;
}

.CrewImg1 {
    width: 330.6px;
    height: 500px;
    margin: auto;
    margin-top: 60px;
    border: 5px #d81013 solid;
    border-radius: 5px;
}

.CrewImg1 image {
    height: 130px;
    margin: auto;
    margin-top: 30px;
    border: 5px #d81013 solid;
    border-radius: 5px;
}

.CrewDesc p {
    margin-top: 10px;
    text-align: center;
    font-size: 1.3em;
    font-weight: 600;
    color: #131e65;

}

.CrewDiv3 {
    margin-top: 50px;
    display: flex;
    justify-content: center;
}

.eachCrew {
    margin-left: 10px;
    width: 330px;

}

.CrewImg3 {
    width: 300px;
    height: 440px;
    margin: auto;
    margin-top: 30px;
    border: 5px #d81013 solid;
    border-radius: 5px;
}

.CrewDiv5 {
    margin-top: 50px;
    display: flex;
    justify-content: center;
}

.CrewImg5 {
    flex: 1;
    margin: auto;
    margin-top: 30px;
    border: 5px #d81013 solid;
    border-radius: 5px;
}

.CrewImg5 img {
    display: block;
    vertical-align: middle; /* or bottom */
}

.TeamImageDiv {
    margin: auto;
    margin-top: 30px;
    width: 900px;
    filter: drop-shadow(0 0 0.75rem rgb(68, 64, 65));
}

.TeamDescDiv {
    width: 60%;
    padding: 20px 80px;
    margin: auto;
    margin-top: 20px;
    border: 1px black solid;
    border-radius: 8px;
    box-shadow: 2px 2px 2px 1px rgba(36, 35, 35, 0.658);
}

.TeamDescDiv p {
    font-weight: 600;
    font-size: 1.3em;
    text-align: center;
    color: #131e65;
}

.Cours {
    width: 100%;
    margin: auto;
    margin-top: 30px;
    display: flex;
}

.Cours a{
    text-decoration: none;
    color: red;
}

.SkillCours {
    flex: 1;
    text-align: center;
}

.BaseCours {
    flex: 1;
    text-align: center;
}

.CoursTitle {
    width: 100%;
    font-size: 1.6em;
    font-weight: 600;
}

.CoursTitle a{
    text-decoration: underline;
    font-size: 1.2em;
    font-weight: 500;
}


.CourDesc {
    margin-top: 10px;
    font-size: 1.3em;
    font-weight: 600;
    color: #d81013;
}

.CourDesc a{
    text-decoration: underline;
    font-size: 1.2em;
    font-weight: 500;
}

.DescMessage{
    width: 40%;
    margin: auto;
    margin-top: 60px;
    border: 2px #0c063f solid;
    padding: 12px 36px;
}

.DescMessage p{
    margin: 5px;
    font-size: 1.3em;
    font-weight: 600;
    color: #131e65;
}

@media screen and (max-width: 1300px) {
    .MainSection {
        width: 95%;
        margin: auto;
    }

    .PageTitleDiv { 
        margin: auto;
        margin-top: 30px;
        text-align: center;
        font-size: 28px;
        width: 80%;
    }
    
    .CrewDiv3 {
        justify-content: center;
        margin-top: 50px;
        display: flex;
        flex-wrap: wrap;
    }

    .CrewImg3 img{
        text-align: center;
    }

    .CrewDiv5 {
        margin-top: 50px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .TeamImageDiv {
        width: 95%;
        margin: auto;
        margin-top: 30px;
        filter: drop-shadow(0 0 0.75rem rgb(68, 64, 65));
    }

    .TeamDescDiv {
        width: 95%;
        padding: 8px 10px;
        margin: auto;
        margin-top: 20px;
        border: 1px black solid;
        border-radius: 8px;
        box-shadow: 2px 2px 2px 1px rgba(36, 35, 35, 0.658);
    }
    
    .Cours {
        width: 100%;
        margin: auto;
        margin-top: 30px;
        display: block;
        flex-wrap: wrap;
        justify-content: center;
    }

    .SkillCours, .BaseCours {
        width: 100%;
        margin-bottom: 50px;
    }

    .CoursTitle {
        font-size: 1.2em;
        font-weight: 600;
    }

    .DescMessage{
        width: 80%;
        margin: auto;
        margin-top: 60px;
        border: 2px #0c063f solid;
        padding: 12px 36px;
    }
}
