.MainSection {
    width: 100%;
    background-color: white;
}

.LogoDiv {
    width: 65%;
    margin: auto;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.LogoImg {
    width: 50%;
    margin-right: 45%;
}

.ContectUsTxt {
    color: #d81213;
    width: auto;
    font-size: 1.5em;
    font-weight: 600;
    white-space: nowrap;
    margin-right: 1em;
}

.FaceBookImg {
    width: 60%;
    height: 60%;
}

.NavbarMenu {
    margin: auto;
    display: flex;
    /* Use flexbox for better control over spacing */
    flex-wrap: nowrap;
    justify-content: center;
    /* Center the items horizontally */
    align-items: center;
    /* Center the items vertically */
    text-align: center;
    background-color: rgb(255, 255, 255);
    border: 1px black solid;
}

.NavbarMenuSize {
    width: 100%;
}

.NavbarMenu a {
    display: inline-block;
    font-size: 20px;
    color: rgb(216, 21, 22);
    padding: 14px 18px;
    text-decoration: none;
}

.NavbarMenu a:hover {
    background-color: rgb(216, 21, 22);
    color: rgb(255, 255, 255);
}

.dropdown {
    display: inline-block;
    border-top: 2px solid #3d3d3d;
    border-bottom: 3px solid #f00;
    margin: 0;
    /* Ensure there is no margin */
    padding: 0;
}

.dropdown .dropbtn {
    width: 100%;
    font-size: 20px;
    border: none;
    outline: none;
    color: rgb(216, 21, 22);
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
}

.navbar a:hover,
.dropdown:hover .dropbtn {
    background-color: rgb(216, 21, 22);
    color: rgb(255, 255, 255);
}

.dropdown_content {
    display: none;
    position: absolute;
    background-color: rgb(255, 255, 255);
    min-width: 220px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown_content a {
    display: block;
    float: none;
    background-color: rgb(255, 255, 255);
    color: rgb(216, 21, 22);
    padding: 8px 22px;
    text-decoration: none;
    text-align: left;
    border: 1px black solid;
    border-radius: 5px;
    margin: 0;
    /* Ensure there is no margin */
    padding: 10px 14px;
    /* Adjust padding as needed */
}

.dropdown_content a:hover {
    background-color: rgb(255, 255, 255);
    color: black;
}

.dropdown:hover .dropdown_content {
    display: block;
}

.MediaMenu {
    display: none;
}

@media screen and (max-width: 1300px) {
    .NavbarMenu {
        display: none;
    }
    
    .MediaMenu {
        width: 50%;
        display: flex;
        /* Use flexbox for centering */
        justify-content: center;
        /* Center horizontally */
        align-items: center;
        /* Center vertically */
        margin: auto;
        margin-bottom: 20px;
        padding-top: 15px;
        cursor: pointer;
        background-color: transparent;
        color: #000000;
        text-align: center;
    }

    .LogoDiv {
        width: 100%;
        margin: auto;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    }
    
    .LogoImg {
        width: 100%;
        margin-right: 45%;
    }
    
    .ContectUsTxt {
        color: #d81213;
        width: auto;
        font-size: 16px;
        font-weight: 600;
        white-space: nowrap;
        margin-right: 0;
    }
}