.MainSection {
    width: 100%;
    height: 65vh;
    margin: auto;
    box-shadow: 0px 0px 10px #e77171;
    overflow: hidden;
}

.ImageSliderBody {
    width: 60%;
    margin: auto;

}

.ImageSliderImg {
    width: 100%;
    height: 0;
    /* Reset height to use padding-bottom trick */
    padding-bottom: 56.25%;
    /* Aspect ratio: 16:9 (height / width * 100) */
    position: relative;
    /* For absolute positioning of the img */
    overflow: hidden;
    /* Hide anything that overflows the container */
}

.ImageSliderImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Maintain aspect ratio and cover the area */
    position: absolute;
    /* Absolute positioning inside the container */
    top: 0;
    left: 0;
}

.nextArrow {
    right: 10px;
    z-index: 1;
    /* Add your custom styles for the next arrow */
    background-color: black;
    border-radius: 25px;
}

.prevArrow {
    left: 10px;
    z-index: 1;
    /* Add your custom styles for the previous arrow */
    background-color: black;
    border-radius: 50%;
}

.prevArrow:hover  {
    left: 10px;
    z-index: 1;
    /* Add your custom styles for the previous arrow */
    background-color: rgb(26, 0, 170);
    border-radius: 50%;
}

.nextArrow:hover {
    right: 10px;
    z-index: 1;
    /* Add your custom styles for the next arrow */
    background-color: rgb(26, 0, 170);
    border-radius: 25px;
}

@media screen and (max-width: 1300px) {
    .MainSection {
        width: 100%;
    }

    .MainSection {
        width: 80%;
        margin: auto;
        height: 100%;
    }

    .ImageSliderBody {
        width: 100%;
        height: 100%;
        margin: auto;
    }

    .ImageSliderBody img {
        width: 100%;
        height: 100%;
        margin: auto;

    }

}