.MainSection {
    width: 80%;
    margin: auto;
}

.TitleDiv{
    text-align: center;
}

.Title {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #5a5a5a;
    font-size: 1.6em;
}

.SubTitle {
    color: #5a5a5a;
    font-size: 1.4em;
    margin-bottom: 40px;
}

.ImgBox2 {
    display: flex;
    justify-content: center;
}

.ImgBox2 img{
    width: 48%;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 8px;
    filter: drop-shadow(0 0 0.35rem rgb(51, 50, 50));
}

.SubTitleDiv {
    margin-top: 20px;
    text-align: center;
}

.ImgBoxAndVideos {
    display: flex;
    justify-content: center;
}

.ImgBoxAndVideos img{
    width: 490px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 8px;
    filter: drop-shadow(0 0 0.35rem rgb(51, 50, 50));
}

.ImgBoxAndVideos img, iframe{
    margin-left: 15px;
    margin-right: 15px;
}

.DescDiv{
    width: 65%;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
}

.DescMessage {
    color: #5a5a5a;
    font-size: 1.3em;
}

.SmallBanner {
    width: 70%;
    margin: auto;
    margin-bottom: 30px;
}

.SmallBanner img{
    border-radius: 8px;
    filter: drop-shadow(0 0 0.75rem rgb(90, 89, 89));
    text-shadow: 0 0 3px rgb(90, 89, 89);
}



@media screen and (max-width: 1300px) {
    .MainSection {
        width: 95%;
        margin: auto;
    }

    .Title {
        margin-top: 10px;
        margin-bottom: 10px;
        color: #5a5a5a;
        font-size: 1.2em;
    }
    
    .SubTitle {
        color: #5a5a5a;
        font-size: 1em;
        margin-bottom: 40px;
    }

    .ImgBox2 {
        display: block;
        margin: auto;
        text-align: center;
    }

    .ImgBox2 img{
        width: 95%;
        border-radius: 12px;
        text-align: center;
        margin-bottom: 20px;
    }
    
    .SubTitleDiv {
        margin-top: 20px;
    }

    .ImgBoxAndVideos {
        display: block;
        text-align: center;
        margin: auto;
    }

    .ImgBoxAndVideos img{
        width: 95%;
        border-radius: 12px;
        filter: drop-shadow(0 0 0.35rem rgb(51, 50, 50));
    }

    .videos{
        width: 95%;
    }

    .DescDiv {
        width: 95%;
        margin: auto;
        margin-top: 10px;
        text-align: center;
    }

    .SmallBanner {
        margin-top: 12px;
         width: 95%;
         text-align: center;
    }

    .SmallBanner img{
        border-radius: 3px;
        filter: drop-shadow(0 0 0.35rem rgb(51, 50, 50));
   }
 }