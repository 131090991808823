.MainSection {
    width: 80%;
    margin: auto;
}

.TitleDiv {
    margin-top: 40px;
    text-align: center;
}

.TitleDiv p {
    color: #dc2d2e;
    font-size: 1.3em;
    text-decoration: underline;
}

.MainImage {
    width: 60%;
    margin: auto;
    margin-top: 20px;
}

.MainImage img {
    border-radius: 12px;
    filter: drop-shadow(0 0 0.35rem rgb(34, 34, 34));
}

.MainImage img {
    width: 100%;
}

.ThreeInOne {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    margin: auto;
    overflow: hidden;
}

.OneSubImage {
    margin: 5px;
    flex: 0.5;
}

.OneSubImage img {
    border-radius: 12px;
    filter: drop-shadow(0 0 0.35rem rgb(34, 34, 34));
}

.OneMainImage {
    margin: 5px;
    flex: 1;
}

.OneMainImage img {
    border-radius: 12px;
    filter: drop-shadow(0 0 0.35rem rgb(34, 34, 34));
}

.SubTitle {
    color: #dc2d2e;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.SubTitle p {
    font-size: 1.3em;
    font-weight: 600;
}

.SubMessage {
    color: #525252;
    text-align: center;
    margin-bottom: 60px;
}

.SubMessage p {
    font-size: 1.3em;
}

.EachAwadrs {
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: auto;
    margin-bottom: 30px;
    display: flex;
}

.AwadrsImgDiv {
    flex: 1;
}

.AwadrsImgDiv img {
    border-radius: 12px;
    filter: drop-shadow(0 0 0.75rem rgb(51, 51, 51));
}

.AwadrsTextDiv {
    padding: 10px 20px;
    flex: 1;
}

.TwoInOne {
    width: 90%;
    margin: auto;
    margin-top: 60px;
    display: flex;
}

.TwoInOneImg {
    margin: 5px;
    flex: 1;
}

.TwoInOneImg img {
    border-radius: 12px;
    filter: drop-shadow(0 0 0.25rem rgb(51, 51, 51));
}

.SubTitle2 {
    margin-top: 10px;
    color: #dc2d2e;
    font-size: 1.5em;
    text-decoration: underline;
}

.SubTitle3 {
    margin-top: 10px;
    color: #dc2d2e;
    font-size: 1.5em;
}

.AwadrsList {
    font-size: 1.3em;
    color: #525252;
    margin-bottom: 10px;
}

.AwadrsTitle {
    font-size: 1.3em;
    color: #dc2d2e;
    margin-bottom: 10px;
    text-decoration: underline;
}

.AwadrsTitle2 {
    font-size: 1.3em;
    color: #dc2d2e;
    margin-bottom: 10px;
}

.AwadrsName{
    margin-bottom: 10px;
}

.TwoInOneDiv {
    margin-bottom: 60px;
}

.SubAwadrs2 {
    margin-left: 100px;
    width: 80%;
}

@media screen and (max-width: 1300px) {
    .MainSection {
        width: 95%;
        margin: auto;
    }

    .MainImage {
        width: 80%;
        margin: auto;
        margin-top: 20px;
    }

    .ThreeInOne {
        display: flex;
        flex-wrap: wrap;
        width: 90%;
        margin: auto;
        overflow: hidden;
    }

    .EachAwadrs {
        width: 90%;
        margin: auto;
        margin-bottom: 30px;
        display: block;
    }

    .AwadrsName{
        font-size: 22px;
    }

    .AwadrsList {
        font-size: 18px;  
    }

    .SubAwadrs2 {
        margin-left: 10px;
        width: 100%;
    
    }
 }