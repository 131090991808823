.MainSection {
    width: 100%;
    margin-bottom: 30px;
}

.NavbarMenu {
    margin: auto;
    display: block; /* Use flexbox for better control over spacing */
    flex-wrap: nowrap;
    justify-content: center; /* Center the items horizontally */
    align-items: center; /* Center the items vertically */
    text-align: center;
    background-color: rgb(255, 255, 255);

}


.NavbarMenu a {
    display: inline-block;
    font-size: 20px;
    color: rgb(216, 21, 22);
    padding: 14px 18px;
    text-decoration: none;
}

.NavbarMenu a:hover {
    background-color: rgb(216, 21, 22);
    color: rgb(255, 255, 255);
}

.dropdown {
    width: 100%;
    border-top: 2px solid #3d3d3d;
    border-bottom: 3px solid #f00;
}

.dropdown .dropbtn {
    width: 100%;
    font-size: 20px;
    border: none;
    outline: none;
    color: rgb(216, 21, 22);
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
}

.navbar a:hover,
.dropdown:hover .dropbtn {
    background-color: rgb(216, 21, 22);
    color: rgb(255, 255, 255);
}

.dropdown_content {
    display: none;
    position: absolute;
    background-color: rgb(255, 255, 255);
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown_content.show {
    display: block;
}

.dropdown_content a {
    display: block;
    float: none;
    background-color: rgb(255, 255, 255);
    color: rgb(216, 21, 22);
    padding: 8px 22px;
    text-decoration: none;
    text-align: center;
    border: 1px black solid;
    border-radius: 3px;
    margin: 0; 
    padding: 10px 14px;
}

.dropdown_content a:hover {
    background-color: rgb(255, 255, 255);
    color: black;
}

/* .dropdown:hover .dropdown_content {
    display: block;
} */

