.MainSection {
    width: 70%;
    margin: auto;
}

.Title {
    border: 2px black solid;
    border-radius: 12px;
    width: 360px;
    margin: auto;
    margin-top: 20px;
    padding: 10px 30px;
}

.Title p{
    text-align: center;
    color: black;
    font-size: 1.6em;
    font-weight: 600;
}

.MainDiv {
    margin-top: 30px;
    display: flex;
}

.Certificate{
    flex: 1;
    width: 30%;
}

.Certificate img{
    border: 2px black solid;
    border-radius: 12px;
    width: 80%;
}

.Item {
    flex: 1;
    width: 30%;
    text-align: center; 
}

.Item a{
    color: white;
    text-decoration: none;
}

.form {
    border: 3px black solid;
    background-color: #d81013;
    border-radius: 12px;
    width: 80%;
    font-size: 0.8em;
    padding: 10px 30px;
    margin: auto;
    margin-bottom: 30px;
    transition: background-color 0.2s;
}

.form a{
    color: white;
    text-decoration: none;
}

.form:hover{
    background-color: #82939e;
}

.Item img{
    border: 2px black solid;
    border-radius: 12px;
    width: 100%;
    margin: auto;
}

.DescDiv {
    width: 60%;
    margin: auto;
    margin-top: 80px;
    border: 3px black solid;
    padding: 10px 30px;
}

.DescDiv p{
    text-align: center;
    font-size: 1.5em;
    font-weight: 500;
}

@media screen and (max-width: 1300px) {
    .MainSection {
        width: 90%;
        margin: auto;
    }

    .Title {
        border: 2px black solid;
        border-radius: 12px;
        width: 80%;
        margin: auto;
        margin-top: 20px;
        padding: 10px 30px;
    }

    .MainDiv {
        margin-top: 30px;
        display: block;
    }
    
    .Certificate{
        flex: 0.8;
        width: 100%;
        margin: auto;
        text-align: center;
    }

    .Item {
        flex: 1.2;
        width: 90%;
        text-align: center;
        margin: auto;
    }

    .form {
        border: 3px black solid;
        background-color: #d81013;
        border-radius: 12px;
        width: 80%;
        font-size: 19px;
        padding: 5px 10px;
        margin: auto;
        margin-bottom: 30px;
        transition: background-color 0.2s;
    }
}